<template>
  <div class="addons-edit mt-4 ml-8 mr-8">
    <v-container fluid v-if="addon">
      <v-row>
        <v-col>
          <h1 class="display-1 mt-3">
            {{ addon.name }}
          </h1>
        </v-col>
        <v-col cols="auto">
          <tooltip-button
            icon="save"
            text="Speichern"
            :color="canBeSaved ? 'primary' : 'default'"
            :dark="false"
            @click="save()"
            classNames="ml-3"
            position="top"
            size="medium"
            :disabled="!canBeSaved"
          />
        </v-col>
      </v-row>
      <v-form v-model="valid" @submit.prevent="save()">
        <v-row>
          <v-col cols="5">
            <v-card outlined elevation="0" class="mb-5">
              <v-card-title class="card-title-grey">
                Eigenschaften
              </v-card-title>
              <v-card-text>
                <v-text-field
                  outlined
                  dense
                  v-model="addon.name"
                  label="Name"
                  type="text"
                  :maxlength="255"
                  :rules="textRules"
                ></v-text-field>
                <v-textarea
                  dense
                  outlined
                  v-model="addon.description"
                  label="Beschreibung"
                >
                </v-textarea>
                <AmountTextField v-model="addon.price" label="Preis" outlined />
              </v-card-text>
            </v-card>
            <v-card outlined elevation="0" class="mb-5">
              <v-card-title class="card-title-grey">
                BP Event
              </v-card-title>
              <v-card-text>
                <v-text-field
                  outlined
                  dense
                  v-model="addon.remote_id"
                  label="ID"
                  type="text"
                  :maxlength="255"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="7" class="pl-8">
            <v-card outlined elevation="0" class="mb-5">
              <v-card-title class="card-title-grey">
                Einheiten
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <h2>{{ addon.addon_items.length }} Einheiten</h2>
                  </v-col>
                  <v-col cols="auto">
                    <div style="margin-top: -10px;">
                      <tooltip-button
                        icon="add"
                        text="Erstellen"
                        color="default"
                        :dark="false"
                        @click="addItem()"
                        classNames="elevation-0"
                        position="top"
                        size="small"
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Seriennummer
                        </th>
                        <th class="text-left">
                          In Reparatur?
                        </th>
                        <th class="text-right">Aktionen</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(addonItem, index) in addon.addon_items"
                        :key="addonItem.id"
                      >
                        <td>
                          <input
                            type="text"
                            v-model="addonItem.serial"
                            style="outline: none"
                          />
                        </td>
                        <td>
                          <v-switch
                            dense
                            v-model="addonItem.is_in_repair"
                            :label="addonItem.is_in_repair ? 'Ja' : 'Nein'"
                          ></v-switch>
                        </td>
                        <td class="text-right">
                          <tooltip-button
                            icon="delete"
                            text="Löschen"
                            color="default"
                            :dark="false"
                            @click="removeItem(index)"
                            classNames="elevation-0 ml-1 mt-1 mb-1"
                            position="top"
                            size="x-small"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import AmountTextField from "@/components/forms/AmountTextField";
import TooltipButton from "@/components/forms/TooltipButton";

export default {
  name: "EditAddon",

  components: { AmountTextField, TooltipButton },

  data: () => ({
    addon: null,
    valid: false,
    dirty: false,
    init: false,
    textRules: [v => !!v || "Muss ausgefüllt sein"]
  }),

  computed: {
    ...mapState("app", ["configs"]),

    addonId() {
      return this.$route.params.id;
    },

    canBeSaved() {
      return this.valid && this.dirty && this.addon;
    }
  },

  watch: {
    addon: {
      handler(value) {
        if (this.init) {
          this.dirty = true;
        }
        this.init = true;
      },
      deep: true
    }
  },

  created() {
    this.fetchData();
  },

  methods: {
    /**
     * Fetch data from server
     */
    async fetchData() {
      try {
        const response = await this.$api.http.get(
          `backoffice/addons/${this.addonId}`
        );
        this.addon = response.data;
      } catch (error) {
        this.$toast.error("Daten konnten nicht vom Server geladen werden.");
      }
    },

    /**
     * Add an item.
     */
    addItem() {
      let num = this.addon.addon_items.length
        ? Math.max(...this.addon.addon_items.map(i => i.serial))
        : 0;
      this.addon.addon_items.push({
        serial: String(num + 1).padStart(4, "0"),
        is_in_repair: false
      });
    },

    /**
     * Remove an item.
     *
     * @param index
     */
    removeItem(index) {
      this.addon.addon_items.splice(index, 1);
    },

    /**
     * Save addon.
     */
    save() {
      let payload = { ...this.addon };
      this.$api.http
        .put(`backoffice/addons/${this.addon.id}`, payload)
        .then(response => {
          this.$toast.success("Das Addon wurde aktualisiert.");
          this.dirty = false;
        })
        .catch(error => {
          this.$toast.error("Das Addon konnte nicht gespeichert werden.");
        });
    }
  }
};
</script>

<style lang="scss">
.addons-edit {
  .card-title-grey {
    background-color: #eee;
    padding: 10px 15px;
    font-size: 16px;
    margin-bottom: 20px;
  }
}
</style>
